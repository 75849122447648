<template>
  <section id="headerContainer" :style="'top:' + headerTopPosition + ';'">
    <header class="header">
      <a id="logoContainer" href="#">
        <img src="../assets/logo_transparent.svg" alt="lendr logo" />
        <h1>Lendr</h1>
      </a>
      <nav class="nav" :class="{ menuActive: isMenuActive }">
        <!--
        <a class="appSvg" href="#"
          >Stake
          <Icon icon="carbon:app" />
        </a>
        <a class="appSvg" href="#"
          >Mint
          <Icon icon="carbon:app" />
        </a>
        -->
        <a href="https://docs.lendr.finance/basics/faqs" target="_blank"
          >FAQs
          <Icon icon="charm:link-external" />
        </a>
        <a href="https://docs.lendr.finance" target="_blank">
          Docs
          <Icon icon="charm:link-external" />
        </a>
        <a href="https://discord.gg/tUyGf7JwQD" target="_blank">
          Community
          <Icon icon="mdi:discord" />
        </a>
        <a href="https://lendrfinance.medium.com" target="_blank">
          Whitepaper
          <Icon icon="mdi:medium" />
        </a>
        <button>Coming Soon</button>
      </nav>
      <Icon
        :icon="menuIconName"
        @click="setMenuActive"
        class="menuIcon"
        :class="{ iconActive: isMenuActive }"
      />
    </header>
    <div
      class="navOverlayDissmis"
      @click="setMenuActive"
      :class="{ menuActive: isMenuActive }"
    ></div>
  </section>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  data() {
    return {
      isMenuActive: false,
      headerTopPosition: "0",
      menuIconName: "ci:menu-alt-05",
    };
  },
  components: {
    Icon,
  },
  watch: {
    isMenuActive() {
      if (this.isMenuActive) {
        this.menuIconName = "carbon:close";
      } else {
        this.menuIconName = "ci:menu-alt-05";
      }
    },
  },
  methods: {
    setMenuActive() {
      this.isMenuActive = !this.isMenuActive;
    },
  },
  mounted() {
    var prevScrollpos = window.pageYOffset;
    window.addEventListener("scroll", () => {
      var currentScrollPos = window.pageYOffset;
      if (window.innerWidth > 800) {
        if (prevScrollpos > currentScrollPos) {
          this.headerTopPosition = "0";
        } else {
          this.headerTopPosition = "-80px";
        }
      }
      prevScrollpos = currentScrollPos;
    });
  },
};
</script>
<style lang="scss">
#headerContainer {
  position: fixed;
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 800px) {
    backdrop-filter: blur(15px);
  }
  @media only screen and (max-width: 800px) {
    background-color: #081930;
  }
  transition: all 0.5s;
  .header {
    padding: 10px 20px 10px 20px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    max-width: 1300px;
    position: relative;
    /*
    @media only screen and (max-width: 1200px) {
      max-width: 1000px;
    }
    */
    #logoContainer {
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: all 0.5s;
      img {
        height: 50px;
        width: 50px;
      }
      h1 {
        font-size: 1.8em;
        color: var(--textColor);
      }
    }
    #logoContainer:hover {
      cursor: pointer;
      filter: drop-shadow(0px 0px 10px var(--secondary));
    }
    .nav {
      display: flex;
      gap: 40px;
      align-items: center;
      .appSvg {
        svg {
          width: 20px;
          height: 20px;
          margin-top: -3px;
        }
      }
      a {
        text-decoration: none;
        color: var(--textColor);
        font-size: 1.2em;

        svg {
          height: 14px;
          width: 14px;
          margin-left: 2px;
          position: absolute;
        }
      }
      a:after {
        content: "";
        display: block;
        width: 0px;
        border-bottom: 1px solid rgb(255, 255, 255);
        transition: 0.3s;
        padding-top: 3px;
      }
      a:hover:after {
        width: calc(100% + 17px);
      }
      button {
        opacity: 0.5;
      }
      button:hover {
        background-color: var(--secondary) !important;
        cursor: auto;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    margin-bottom: 30px;
  }
  .menuIcon {
    @media only screen and (min-width: 800px) {
      display: none;
    }
  }
  @media only screen and (max-width: 800px) {
    .nav {
      position: fixed;
      right: 0;
      top: 0;
      flex-direction: column;
      padding: 80px 40px 40px 40px;
      height: 100vh;
      //backdrop-filter: blur(15px);
      background-color: var(--background);
      transition: -webkit-transform 0.5s ease;
      transition: -o-transform 0.5s ease;
      transition: -ms-transform 0.5s ease;
      transition: transform 0.5s ease;
      -webkit-transform: translateX(260px);
      -ms-transform: translateX(260px);
      -o-transform: translateX(260px);
      transform: translateX(260px);
      gap: 5px !important;
      z-index: 2;
      width: 200px;
      a {
        width: 100%;
        padding: 12px 0 12px 0;
      }
      a:after {
        width: 0;
      }
      button {
        margin-top: 30px;
      }
    }
    .menuActive {
      -webkit-transform: translateX(0) !important;
      -ms-transform: translateX(0) !important;
      -o-transform: translateX(0) !important;
      transform: translateX(0) !important;
    }
    .iconActive {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    .menuIcon {
      z-index: 4;
      width: 40px;
      height: 40px;
      color: var(--textColor);
      transition: -webkit-transform 0.4s ease;
      transition: -o-transform 0.4s ease;
      transition: -ms-transform 0.4s ease;
      transition: transform 0.4s ease;
    }
    .navOverlayDissmis {
      transition: -webkit-transform 0.4s ease;
      transition: -o-transform 0.4s ease;
      transition: -ms-transform 0.4s ease;
      transition: transform 0.4s ease;
      -webkit-transform: translateX(100vw);
      -ms-transform: translateX(100vw);
      -o-transform: translateX(100vw);
      transform: translateX(100vw);
      position: fixed;
      overflow: hidden;
      backdrop-filter: blur(4px);
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      background: -webkit-linear-gradient(
        270deg,
        var(--background) 200px,
        var(--lightTransparent) 100%
      );
      background: -moz-linear-gradient(
        270deg,
        var(--background) 200px,
        var(--lightTransparent) 100%
      );
      background: -o-linear-gradient(
        270deg,
        var(--background) 200px,
        var(--lightTransparent) 100%
      );
      background: linear-gradient(
        270deg,
        var(--background) 200px,
        var(--lightTransparent) 100%
      );
    }
  }
}
</style>
